import { CertificateType } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

export interface ITransferConfirmModalProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    onOk: (shid: string) => void
    onSign: (shid: string) => void
}

export const TransferConfirmModal: FC<ITransferConfirmModalProps> = ({
    isModalVisible,
    setIsModalVisible,
    onOk,
    onSign,
}) => {
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)

    const handleOk = (certificate: CertificateType) => {
        onOk(certificate.skid)
    }

    const handleSignOk = (certificate: CertificateType) => {
        setIsModalVisible(false)
        onSign(certificate.skid)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <MiniModal visible={isModalVisible} onCancel={handleCancel} width={600}>
                <div>
                    <Box direction="row" align="center">
                        <Box className="modal-content-shift">
                            <QuestionCircle className="modal-icon modal-question-icon" />
                        </Box>
                        <div className="ant-modal-title">Передать ЦФА</div>
                    </Box>
                    <Box direction="row" align="center" justify="right">
                        <div className="modal-content-shift">&nbsp;</div>
                        <Box padding={[0, 32, 24, 0]}>
                            <p className="modal-content-text">
                                Вы действительно хотите передать ЦФА? Это действие нельзя будет
                                отменить.
                            </p>
                        </Box>
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 0, 24, 0]}
                        direction="row"
                        justify="right"
                    >
                        <Button borderRadius={12} onClick={handleCancel}>
                            Отмена
                        </Button>
                        <Button
                            borderRadius={12}
                            type="primary"
                            onClick={() => {
                                setIsVisibleCertificateModal(true)
                            }}
                        >
                            Да, передать ЦФА
                        </Button>
                    </Box>
                </div>
            </MiniModal>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={handleOk}
                onSign={handleSignOk}
            />
        </>
    )
}
