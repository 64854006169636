import { CertificateType } from '@dltru/dfa-models'
import {
    Alert,
    Box,
    Button,
    ConditionsModal,
    MiniModal,
    Price,
    QuestionCircle,
    Typography,
} from '@dltru/dfa-ui'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'
import { moneySelector } from '@store/money/selectors'

import { transferAcceptAgreementText } from '@pages/OwnershipTransfer/resources'

import { WalletWidget } from '@components/WalletWidget'
import { getCalculateOrderAcceptFeeHelper } from '@store/helper'
import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    onOk: (skid: string) => void
    onSign: (skid: string) => void
    id: number
}

export const ApproveModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible, onOk, id, onSign }) => {
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const [isConditionModalVisible, setIsConditionModalVisible] = useState(false)
    const totalAmount = useSelector(dfaTransferSelector.selecAllAmountAcceptant)
    const acceptantFee = useSelector(dfaTransferSelector.selecAcceptantFee)
    const accountInfo = useSelector(moneySelector.selectAccount)
    const [isDisable, setDisable] = useState(false)
    const [fee, setFee] = useState<number>(0)

    const handleOk = (certificate: CertificateType) => {
        onOk(certificate.skid)
    }

    const handleSignOk = (certificate: CertificateType) => {
        setIsModalVisible(false)
        onSign(certificate.skid)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    useEffect(() => {
        if (accountInfo?.balance?.available) {
            if (fee / 100 + acceptantFee + (totalAmount || 0) > accountInfo?.balance.available) {
                setDisable(true)
            } else {
                setDisable(false)
            }
        } else {
            setDisable(true)
        }
    }, [accountInfo?.balance.available, acceptantFee, fee, totalAmount])


    const calculateFee = async (order_id: number) => {
        const feeFromApi = await getCalculateOrderAcceptFeeHelper(order_id)
        setFee(feeFromApi?.item ?? 0)
    }
    useEffect(() => {
        if(!id) {return}
        calculateFee(id);
    }, [id])

    return (
        <>
            <MiniModal visible={isModalVisible} onCancel={handleCancel} width={600}>
                <div>
                    <Box direction="row" align="center">
                        <Box className="modal-content-shift">
                            <QuestionCircle className="modal-icon modal-question-icon" />
                        </Box>
                        <div className="ant-modal-title">Принять оферту</div>
                    </Box>
                    <Box direction="row" align="center" justify="right">
                        <div className="modal-content-shift">&nbsp;</div>
                        <Box padding={[0, 32, 24, 0]}>
                            <p className="modal-content-text">
                                Вы действительно хотите принять Оферту на описанных в ней условиях?
                            </p>
                            <Box>
                              <Typography.Text>Комиссия за акцепт оферты</Typography.Text>
                              <Price price={fee ? fee / 100 : 0} />
                            </Box>
                            <WalletWidget
                                balance={accountInfo?.balance?.available}
                                account={String(accountInfo?.number)}
                                error={
                                    (accountInfo?.balance?.available || 0) <
                                    acceptantFee + (totalAmount || 0)
                                }
                            />
                            <br />
                            <Alert
                                width={456}
                                message="Уважаемый пользователь!"
                                description="Принимая Оферту, вы заключаете сделку с Оферентом. Все расчеты по такой сделке осуществляются вне Платформы. Оператор не осуществляет расчетов по данной сделке с ЦФА, не контролирует процесс ее исполнения и не отвечает за неисполнение или ненадлежащее исполнение сторонами обязательств из такой сделки."
                                type="info"
                                showIcon
                            />
                        </Box>
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 0, 24, 0]}
                        direction="row"
                        justify="right"
                    >
                        <Button borderRadius={12} onClick={handleCancel}>
                            Отмена
                        </Button>
                        <Button
                            disabled={isDisable}
                            borderRadius={12}
                            type="primary"
                            onClick={() => {
                                setIsVisibleCertificateModal(true)
                            }}
                        >
                            Да, принять оферту
                        </Button>
                    </Box>
                    <div className="modal-footer-gray-text">
                        Принимая Оферту, вы соглашаетесь с{' '}
                        <Button type="linkText" onClick={() => setIsConditionModalVisible(true)}>
                            условиями
                        </Button>
                        .
                    </div>
                </div>
            </MiniModal>
            <ConditionsModal
                isModalVisible={isConditionModalVisible}
                setIsModalVisible={setIsConditionModalVisible}
                title="Условия"
                text={transferAcceptAgreementText}
            />
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={handleOk}
                onSign={handleSignOk}
            />
        </>
    )
}
