import { IssueAvailableInvestorsType, TransferOrderStatus } from '@dltru/dfa-models'
import { Alert, Box } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import {
    checkLimitRecipientTransferAction,
    dfaOwnershipTransferSlice,
} from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

export const AlertOfferBlock: FC = () => {
    const showLimitAlert = useSelector(dfaTransferSelector.showLimitAlert)
    const showQualAlert = useSelector(dfaTransferSelector.showQualAlert)
    const isMyTransfer = useSelector(dfaTransferSelector.isMyTransfer)
    const offerData = useSelector(dfaTransferSelector.selectData)
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const reduxDispatch = useDispatch()
    useEffect(() => {
        if (
            isMyTransfer &&
            offerData?.status === TransferOrderStatus.accepted &&
            (dfa.issue_available_investors_type === IssueAvailableInvestorsType.qualified ||
                dfa.issue_available_investors_type_limits ===
                    IssueAvailableInvestorsType.qualified_and_unqualified_with_limit)
        ) {
            reduxDispatch(checkLimitRecipientTransferAction(offerData.buyer_id as string))
        }
        return () => {
            reduxDispatch(dfaOwnershipTransferSlice.actions.clearDFAOwnershipBuyerData())
        }
    }, [
        offerData?.status,
        dfa?.issue_available_investors_type,
        dfa?.issue_available_investors_type_limits,
    ])

    if (!showLimitAlert && !showQualAlert) {
        return null
    }

    return (
        <Box margin={[0, 0, 8, 0]}>
            {showLimitAlert && (
                <Alert
                    showIcon
                    type="warning"
                    description={`Превышен годовой лимит покупок с учетом настоящей Оферты. ${
                        offerData?.status === TransferOrderStatus.accepted
                            ? 'Оферта не может быть исполнена.'
                            : 'Вы можете принять Оферту, но ее исполнение будет невозможным, если на момент исполнения обязательств Ваш лимит покупок будет превышен'
                    }`}
                />
            )}
            {showQualAlert && (
                <Alert
                    showIcon
                    type="warning"
                    description={`ЦФА доступны для приобретения только только квалифицированным инвесторам. ${
                        offerData?.status === TransferOrderStatus.accepted
                            ? 'Оферта не может быть исполнена, так как получатель ЦФА не является квалифицированным инвестором'
                            : 'Вы можете принять Оферту, но ее исполнение будет возможным только в том случае, если на момент исполнения обязательств вам будет присвоен статус квалифицированного инвестора'
                    }`}
                />
            )}
        </Box>
    )
}
