import { IssueAvailableInvestorsType, TransferOrderStatus } from '@dltru/dfa-models'
import { CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import { Button } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

interface ITransferRequestFooter {
    onApprove: () => void
    onDeclineSelf: () => void
    onConfirmSelf: () => void
}

const Footer: FC<ITransferRequestFooter> = ({ onApprove, onDeclineSelf, onConfirmSelf }) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const order = useSelector(dfaTransferSelector.selectData)
    const qualifyInfo = useSelector(profileSelector.selectQualifyInfo)
    const offer = useSelector(dfaTransferSelector.selectOfferentOffer)
    const isMyAccepted = useSelector(dfaTransferSelector.isMyAcceptedTransfer)
    const isMyTransfer = useSelector(dfaTransferSelector.isMyTransfer)
    const showLimitAlert = useSelector(dfaTransferSelector.showLimitAlert)
    const showQualAlert = useSelector(dfaTransferSelector.showQualAlert)
    const showDeclineBtn =
        order?.status === TransferOrderStatus.placed && isMyTransfer && !order.noCancel

    /*const showConfirmBtn =
        order?.status === TransferOrderStatus.accepted &&
        isMyTransfer &&
        //!order.show_transfer_button &&
        order.seller_asset_data.asset_id &&
        order.seller_asset_data.asset_id > 0*/

    const isDisableBtn =
        qualifyInfo?.is_qualified === false &&
        offer?.dfa?.issue_available_investors_type === IssueAvailableInvestorsType.qualified

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {showDeclineBtn && (
                            <Button onClick={onDeclineSelf} borderRadius={12}>
                                Отозвать оферту
                            </Button>
                        )}
                        {order?.show_transfer_button && (
                            <Button
                                type="primary"
                                onClick={onConfirmSelf}
                                borderRadius={12}
                                disabled={showLimitAlert || showQualAlert}
                            >
                                Передать ЦФА
                            </Button>
                        )}
                        {isMyAccepted && (
                            <Button
                                onClick={onApprove}
                                borderRadius={12}
                                type="primary"
                                disabled={isDisableBtn}
                            >
                                Принять оферту
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}

export default Footer
