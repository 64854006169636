import { ITransferOrderShort, OrderTypeEnum, TransferOrderStatus } from '@dltru/dfa-models'
import { Box, DateTimeCell, PageHeader, Tabs, Tag, getOrderStatusTagProps } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import {
    acceptDFAOwnershipTransfer,
    acceptDFAOwnershipTransferPrefly,
    clearOwnershipTransfer,
    executeDFATransfer,
    executeDFATransferPrefly,
    getDFAOwnershipTransfer,
    transferCancelByOwner,
    transferCancelByOwnerPrefly,
} from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'
import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'

import { AlertOfferBlock } from './components/AlertOfferBlock'
import { ApproveModal } from './components/ApproveModal'
import { DFAOwnershipReceiver } from './components/DFAOwnershipReceiver'
import Footer from './components/Footer'
import { TransferConfirmModal } from './components/TransferConfirmModal'
import { TransferOrdersTable } from './components/TransferOrdersTable/TransferOrdersTable'
import { TransferRevokeModal } from './components/TransferRevokeModal'

const { TabPane } = Tabs

export const DFAOwnershipTransferRequestPage: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()

    const { request: globalId } = useParams()

    const dfaOwnershipTransferData = useSelector(dfaTransferSelector.selectData)
    const uuid = useSelector(authSelector.selectUserUid)
    const isMyAccepted = useSelector(dfaTransferSelector.isMyAcceptedTransfer)
    const isCheckQuota = useSelector(profileSelector.isCheckQuota)
    const [approveModal, setApproveModal] = useState(false)
    const [declineSelfModal, setDeclineSelfModal] = useState(false)
    const [confirmSelfModal, setConfirmSelfModal] = useState(false)

    useEffect(() => {
        if (globalId && uuid) {
            reduxDispatch(getDFAOwnershipTransfer({ globalId: globalId }))
        }

        return () => {
            reduxDispatch(clearOwnershipTransfer())
        }
    }, [globalId, uuid])

    useEffect(() => {
        if (isMyAccepted && isCheckQuota) {
            reduxDispatch(profileDetailsSlice.actions.getQualifyInfo())
        }
    }, [isMyAccepted, isCheckQuota])

    const statusTagProps = getOrderStatusTagProps(
        dfaOwnershipTransferData?.status || '',
        OrderTypeEnum.transfer,
        dfaOwnershipTransferData?.cancel_initiator,
    )

    const cancelByOfferor = (skid: string) => {
        if (dfaOwnershipTransferData?.uuid) {
            reduxDispatch(
                transferCancelByOwnerPrefly({
                    external_deal_id: dfaOwnershipTransferData.uuid,
                    skid,
                }),
            )
        }
    }

    const signCancelByOfferor = (skid: string) => {
        if (dfaOwnershipTransferData?.uuid) {
            reduxDispatch(
                transferCancelByOwner({
                    external_deal_id: dfaOwnershipTransferData.uuid,
                    skid,
                }),
            )
            navigate(-1)
        }
    }

    const isShowTab =
        dfaOwnershipTransferData?.status === TransferOrderStatus.accepted ||
        dfaOwnershipTransferData?.status === TransferOrderStatus.executed ||
        dfaOwnershipTransferData?.status === TransferOrderStatus.in_progress ||
        dfaOwnershipTransferData?.status === TransferOrderStatus.ready

    const title = isShowTab
        ? `Сделка  №${dfaOwnershipTransferData?.id || ''}`
        : `Оферта  №${dfaOwnershipTransferData?.id || ''}`

    const tabTitle = isShowTab ? 'Условия сделки' : 'Условия оферты'

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={title}
                    onBack={() => navigate(-1)}
                    tags={<Tag color={statusTagProps.color}>{statusTagProps.title}</Tag>}
                    subTitle={<>от {DateTimeCell(dfaOwnershipTransferData?.created_at)}</>}
                />
            </Box>
            <AlertOfferBlock />
            <Tabs>
                <TabPane tab={tabTitle} key={1}>
                    <DFAOwnershipReceiver data={dfaOwnershipTransferData as ITransferOrderShort} />
                </TabPane>
                {isShowTab && (
                    <TabPane tab="Исполнение сделки" key={2}>
                        <Box padding={[0, 24, 24, 24]} className="tablebox">
                            <TransferOrdersTable
                                data={dfaOwnershipTransferData as ITransferOrderShort}
                            />
                        </Box>
                    </TabPane>
                )}
            </Tabs>

            <Footer
                onApprove={() => setApproveModal(true)}
                onConfirmSelf={() => setConfirmSelfModal(true)}
                onDeclineSelf={() => setDeclineSelfModal(true)}
            />
            <ApproveModal
                isModalVisible={approveModal}
                setIsModalVisible={setApproveModal}
                id={dfaOwnershipTransferData?.id || 0}
                onOk={(skid: string) => {
                    if (dfaOwnershipTransferData?.uuid) {
                        reduxDispatch(
                            acceptDFAOwnershipTransferPrefly({
                                external_deal_id: dfaOwnershipTransferData.uuid,
                                skid,
                            }),
                        )
                        //navigate(-1)
                    }
                }}
                onSign={(skid: string) => {
                    if (dfaOwnershipTransferData?.uuid) {
                        reduxDispatch(
                            acceptDFAOwnershipTransfer({
                                external_deal_id: dfaOwnershipTransferData.uuid,
                                skid,
                            }),
                        )
                        //navigate(-1)
                    }
                }}
            />
            <TransferRevokeModal
                isModalVisible={declineSelfModal}
                setIsModalVisible={setDeclineSelfModal}
                onOk={cancelByOfferor}
                onSignOk={signCancelByOfferor}
            />
            <TransferConfirmModal
                isModalVisible={confirmSelfModal}
                setIsModalVisible={setConfirmSelfModal}
                onOk={(skid: string) => {
                    if (dfaOwnershipTransferData?.uuid) {
                        reduxDispatch(
                            executeDFATransferPrefly({
                                external_deal_id: dfaOwnershipTransferData.uuid,
                                skid,
                            }),
                        )
                        //navigate(-1)
                    }
                }}
                onSign={(skid: string) => {
                    if (dfaOwnershipTransferData?.uuid) {
                        reduxDispatch(
                            executeDFATransfer({
                                external_deal_id: dfaOwnershipTransferData.uuid,
                                skid,
                            }),
                        )
                        //navigate(-1)
                    }
                }}
            />
        </>
    )
}
